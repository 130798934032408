// src/components/PricingCard.tsx
import React from 'react';
import './PricingCard.css';

interface PricingCardProps {
  title: string;
  price: string;
  oldPrice?: string;
  monthlyPrice: string;
  features: string[];
  buttonText: string;
  gradient: string;
  tag?: string;
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  price,
  oldPrice,
  monthlyPrice,
  features,
  buttonText,
  gradient,
  tag,
}) => {
  return (
    <div className="pricing-card" style={{ background: gradient }}>
      {tag && <span className="pricing-tag">{tag}</span>}
      <h3>{title}</h3>
      <div className="price-container">
        {oldPrice && <span className="old-price">{oldPrice}</span>}
        <h1>{price}</h1>
        {price !== 'Personalizado' && (
          <>
            <p className="billing-info">Por usuario/mes (facturado anualmente)</p>
            <p className="monthly-price">{monthlyPrice} si prefieres pago mensual</p>
          </>
        )}
      </div>
      <ul className="feature-list">
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <a
        href="https://converflow.ai/register"
        target="_blank"
        rel="noopener noreferrer"
        className="pricing-button"
      >
        {buttonText} →
      </a>
    </div>
  );
};

export default PricingCard;

