// src/pages/Home.tsx
import React from 'react';
import FeatureBox from '../components/FeatureBox';
import SupportFeatures from '../components/SupportFeatures';
import './Home.css';

const Home: React.FC = () => {
  return (
    <div className="home">
      <FeatureBox />
      <SupportFeatures />
    </div>
  );
};

export default Home;
