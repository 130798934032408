// src/components/Navbar.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo.png';

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
      </div>
      <ul className="navbar-menu">
        <li><Link to="/">Solución</Link></li>
        <li><Link to="/pricing">Precios</Link></li>
        <li><Link to="/about">Nosotros</Link></li>
      </ul>
      
      <div className="navbar-login">
        <a href="https://converflow.ai/register"><button className="register-button">Registrarme</button></a>
        <a href="https://app.converflow.ai/"><button>Log In</button></a>
      </div>
    </nav>
  );
};

export default Navbar;
