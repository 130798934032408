// src/pages/Pricing.tsx
import React from 'react';
import PricingCard from '../components/PricingCard';
import './Pricing.css';

const Pricing: React.FC = () => {
  return (
    <div className="pricing-page">
      <PricingCard
        title="BASIC - 30 días de Prueba Gratuita"
        price="59 €"
        monthlyPrice="75 €"
        features={["1 Agente", "100 Conversaciones", "1 Usuario", "3 Campañas"]}
        buttonText="Comienza gratis"
        gradient="linear-gradient(to bottom, #9E80B4, #8BB5DA)"
      />
      <PricingCard
        title=""
        price="80 €"
        monthlyPrice="97 €"
        features={["5 Agentes", "Conversaciones Ilimitadas", "Campañas Ilimitadas", "Integración con CRM"]}
        buttonText="Obtener Ilimitado"
        gradient="linear-gradient(to bottom, #8CB5A5, #6DB192)"
        tag="Mejor Opción para PYMES"
      />
      <PricingCard
        title="Empresarial"
        price="Personalizado"
        monthlyPrice=""
        features={["Todo en Ilimitado +", "Exportación Masiva", "Analítica Avanzada", "Seguridad Avanzada"]}
        buttonText="Contacta para un plan a medida"
        gradient="linear-gradient(to bottom, #D98C82, #E4A76A)"
      />
    </div>
  );
};

export default Pricing;
