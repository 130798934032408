// src/pages/AboutUs.tsx
import React from 'react';
import './AboutUs.css';
import hero from '../assets/hero-image.jpg';
import team1 from '../images/team1.png';
import team2 from '../images/team2.png';
import team3 from '../images/team3.png';

const AboutUs: React.FC = () => {
  return (
    <div className="about-us-page">
      <section className="hero-section">
        <div className="hero-content">
          <h1>Sobre Converflow</h1>
          <p>
            Transformamos la forma en que las empresas interactúan con sus clientes, 
            brindando soluciones de automatización inteligentes y personalizadas para 
            mejorar la productividad de los equipos de ventas y marketing.
          </p>
        </div>
        <div className="hero-image">
          <img src={hero} className="imagen" alt="Equipo de Converflow trabajando" />
        </div>
      </section>

      <section className="values-section">
        <div className="value-card">
          <h2>Misión</h2>
          <p>
            Ayudar a las empresas a optimizar su comunicación mediante automatización 
            inteligente, permitiendo que cada interacción sea personalizada y efectiva.
          </p>
        </div>
        <div className="value-card">
          <h2>Visión</h2>
          <p>
            Ser líderes en la automatización de conversaciones, facilitando a las empresas 
            el crecimiento y la mejora continua en su relación con los clientes.
          </p>
        </div>
        <div className="value-card">
          <h2>Valores</h2>
          <p>
            Innovación, compromiso con la excelencia y un enfoque centrado en las personas 
            son los pilares que nos guían en cada paso que damos.
          </p>
        </div>
      </section>

      <section className="team-section">
        <h2>Conoce a Nuestro Equipo</h2>
        <p>Un equipo de expertos comprometidos con el crecimiento de tu empresa.</p>
        <div className="team-grid">
          <div className="team-member">
            <img src={team1} alt="CEO de Converflow" />
            <h3>Jose Luis Castedo</h3>
            <p>CEO & Co-Fundador</p>
          </div>
          <div className="team-member">
            <img src={team2} alt="CTO de Converflow" />
            <h3>Luis Escobar</h3>
            <p>COO & Co-Fundador</p>
          </div>
          <div className="team-member">
            <img src={team3} alt="Directora de Marketing" />
            <h3>Leifer Mendez</h3>
            <p>CTO</p>
          </div>
        </div>
      </section>

      <section className="cta-section">
        <h2>¿Quieres saber más sobre cómo Converflow puede transformar tu negocio?</h2>
        <a href="https://converflow.ai/register" className="cta-button">
          Agenda una Demo →
        </a>
      </section>
    </div>
  );
};

export default AboutUs;
